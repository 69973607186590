import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss'
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

function fun() {
   let doc = document.getElementById("root");
   let docW = doc.clientWidth / 10 + 'px';
   doc.style.fontSize = docW;
}
fun();
window.addEventListener("resize", fun);
root.render(
   <BrowserRouter
      basename={process.env.REACT_APP_ENV === "test" ? "mlxx313" : "/"}
   >
      <App />
   </BrowserRouter>

);
